import { useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import QRCode from 'react-qr-code';

import ChildCard from '../../common/components/ChildCard';
import CustomModal from '../../common/components/Modal';
import { calculateAge } from '../../utils';
import useMediaQuery from '@mui/material/useMediaQuery';

import FirstAidInfo from './EditProfileModals/FirstAidInfo';
import AddressModal from './EditProfileModals/AddressModal';
import AlternativeContactModal from './EditProfileModals/AlternativeContactModal';
import DeleteProfileModal from './EditProfileModals/DeleteProfileModal';
import { EditProfileColor } from './EditProfileModals/EditProfileColor';

const StepNumbers = Object.freeze({
  NONE: 0,
  EDIT_DETAILS: 1,
  DELETE_PROFILE: 'Delete',
  FIRST_AID_INFO: 'First Aid Info',
  ADDRESS: 'Address',
  ALTERNATIVE_CONTACT_NO: 'Alternative Contact No',
  PROFILE_COLOR: 'Profile Color',
});

export default function QRModal({
  childDetail,
  open,
  refetchChildren,
  handleClose,
}) {
  const isMobile = useMediaQuery('(max-width:768px)');

  const [stepNo, setStepNo] = useState(StepNumbers.NONE);

  const {
    minutes,
    gender,
    dob,
    profileFirstName,
    profileLastName,
    profileCardColor,
    id: profileId,
  } = childDetail;
  const childDetailsJson = JSON.stringify(childDetail?.barcode);

  const handleStepChange = (newStep) => {
    setStepNo(newStep);
  };

  let content;
  switch (stepNo) {
    case StepNumbers.NONE:
      content = (
        <>
          <ChildCard
            isEditModal
            profileId={profileId}
            mins={minutes}
            male={gender === 'Male'}
            age={calculateAge(dob)}
            fname={profileFirstName}
            lname={profileLastName}
            profileCardColor={profileCardColor}
          />
          <Grid mt={1} container spacing={1} justifyContent={'center'}>
            <Grid item xs={9.5}>
              <Box
                px={2}
                sx={{ cursor: 'pointer' }}
                py={2}
                display="flex"
                justifyContent={'center'}
                boxShadow={'0px 1px 4px 0px #00000029'}
                borderRadius={2}
                onClick={() => setStepNo(1)}
              >
                <Typography pr={1} fontWeight={600} color="#4B3E3D">
                  Edit Details
                </Typography>
                <img src="/pen.svg" alt="pen" />
              </Box>
            </Grid>
            {minutes <= 0 && (
              <Grid item xs={2.5}>
                <Box
                  sx={{ cursor: 'pointer' }}
                  display="flex"
                  height="100%"
                  p="1rem"
                  justifyContent={'center'}
                  borderRadius={2}
                  boxShadow={'0px 1px 4px 0px #00000029'}
                  onClick={() => setStepNo('Delete')}
                >
                  <img src="/bin.svg" alt="Delete" />
                </Box>
              </Grid>
            )}
          </Grid>
          <Box width={'100%'} mt="20px">
            <QRCode size={isMobile ? '270' : '336'} value={childDetailsJson} />
          </Box>
          <Box
            color="#4B3E3D"
            pt={2}
            onClick={handleClose}
            display="flex"
            justifyContent={'center'}
            sx={{ cursor: 'pointer' }}
          >
            <Box component="img" mr="10px" src="/back.svg" alt="back"></Box>{' '}
            <span> Back</span>
          </Box>
        </>
      );
      break;
    case StepNumbers.DELETE_PROFILE:
      content = (
        <DeleteProfileModal
          childDetail={childDetail}
          handleClose={handleClose}
          refetchChildren={refetchChildren}
        />
      );
      break;
    case StepNumbers.EDIT_DETAILS:
      content = (
        <Box display="flex" flexDirection="column" gap="9px">
          <Typography
            color={'black.light'}
            fontSize="28px"
            fontWeight="700"
            textAlign="center"
          >
            Edit Details
          </Typography>
          <Box
            px={2}
            sx={{ cursor: 'pointer' }}
            py={2}
            display="flex"
            justifyContent={'center'}
            boxShadow={' 0px 1px 4px 0px #00000029'}
            borderRadius={2}
            onClick={() => setStepNo('First Aid Info')}
          >
            <Typography pr={1} fontWeight={600} color="#4B3E3D">
              First Aid Info
            </Typography>
            <img src="/pen.svg" alt="pen" />
          </Box>
          <Box
            px={2}
            sx={{ cursor: 'pointer' }}
            py={2}
            display="flex"
            justifyContent={'center'}
            boxShadow={' 0px 1px 4px 0px #00000029'}
            borderRadius={2}
            onClick={() => setStepNo('Address')}
          >
            <Typography pr={1} fontWeight={600} color="#4B3E3D">
              Address
            </Typography>
            <img src="/pen.svg" alt="pen" />
          </Box>
          <Box
            px={2}
            sx={{ cursor: 'pointer' }}
            py={2}
            display="flex"
            justifyContent={'center'}
            boxShadow={' 0px 1px 4px 0px #00000029'}
            borderRadius={2}
            onClick={() => setStepNo('Profile Color')}
          >
            <Typography pr={1} fontWeight={600} color="#4B3E3D">
              Edit Background Color
            </Typography>
            <img src="/pen.svg" alt="pen" />
          </Box>
          <Box
            px={2}
            sx={{ cursor: 'pointer' }}
            py={2}
            display="flex"
            justifyContent={'center'}
            boxShadow={' 0px 1px 4px 0px #00000029'}
            borderRadius={2}
            onClick={() => setStepNo('Alternative Contact No')}
          >
            <Typography pr={1} fontWeight={600} color="#4B3E3D">
              Alternative Contact No
            </Typography>
            <img src="/pen.svg" alt="pen" />
          </Box>

          <Box
            color="#4B3E3D"
            pt={2}
            display="flex"
            justifyContent={'center'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setStepNo(0)}
          >
            <Box
              onClick={() => setStepNo(0)}
              component="img"
              mr="10px"
              src="/back.svg"
              alt="back"
            ></Box>
            <span> Back</span>
          </Box>
        </Box>
      );
      break;
    case StepNumbers.FIRST_AID_INFO:
      content = (
        <FirstAidInfo
          setStepNo={handleStepChange}
          handleClose={handleClose}
          childDetail={childDetail}
          refetchChildren={refetchChildren}
        />
      );
      break;
    case StepNumbers.ADDRESS:
      content = (
        <AddressModal
          setStepNo={handleStepChange}
          handleClose={handleClose}
          childDetail={childDetail}
          refetchChildren={refetchChildren}
        />
      );
      break;
    case StepNumbers.ALTERNATIVE_CONTACT_NO:
      content = (
        <AlternativeContactModal
          childDetail={childDetail}
          setStepNo={handleStepChange}
          handleClose={handleClose}
          refetchChildren={refetchChildren}
        />
      );
      break;
    case StepNumbers.PROFILE_COLOR:
      content = (
        <EditProfileColor
          setStepNo={handleStepChange}
          handleClose={handleClose}
          childDetail={childDetail}
          refetchChildren={refetchChildren}
        />
      );
      break;
    default:
      content = null;
  }

  return (
    <div>
      <CustomModal
        width={isMobile ? 320 : 400}
        handleClose={handleClose}
        open={open}
      >
        {content}
      </CustomModal>
    </div>
  );
}
