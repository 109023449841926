import { Grid } from '@mui/material';
import { useGetAllPackagesQuery } from '../../services/apis/PackagesService';
import { useDispatch, useSelector } from 'react-redux';
import { toggleItemInCart } from '../../store/slices/CartSlice';

import styles from './packages.module.scss';
import PackageCard from './PackageCard';
import CardSkeleton from '../../common/components/Skeletons/CardSkeleton';
import { setShowCartPopup } from '../../store/slices/BookingSlice';
const Packages = () => {
  const dispatch = useDispatch();

  const { data, error, isLoading } = useGetAllPackagesQuery();
  const { items } = useSelector((state) => state.cart);

  const formattedData = data
    ? data.map((item) => ({
        ...item,
        features: [item.highlights1, item.highlights2, item.highlights3].filter(
          Boolean
        ),
      }))
    : [];

  const handleButtonClick = (item) => {
    dispatch(toggleItemInCart(item));

    const isItemSelected = items.find((cartItem) => cartItem.id === item.id);
    if (!isItemSelected) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      dispatch(setShowCartPopup(true));

      setTimeout(() => {
        dispatch(setShowCartPopup(false));
      }, 2000);
    }
  };

  if (isLoading)
    return (
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <CardSkeleton />
      </Grid>
    );
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={3}>
        {formattedData.map((packageItem, index) => (
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <PackageCard
              index={index}
              id={packageItem.id}
              packageItem={packageItem}
              handleButtonClick={() => handleButtonClick(packageItem, index)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Packages;
