import React from 'react';
import styles from './packages.module.scss';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import CustomButton from '../../common/components/CustomButton';
import { useSelector } from 'react-redux';
import Bullet from './Bullet';

export default function PackageCard({
  index,
  id,
  packageItem,
  handleButtonClick,
}) {
  const { items } = useSelector((state) => state.cart);

  const isSelected = items.find((item) => item.id === id);

  const isPackageDisabled = (id) => {
    const isBirthdayPackSelected = items.some((item) => item.id === 6);

    if (isBirthdayPackSelected && id !== 6) {
      return true;
    } else if (items.length > 0 && !isBirthdayPackSelected && id === 6) {
      return true;
    }
  };

  return (
    <Box px={3} py={2} className={styles.customBoxShadow}>
      {packageItem.mostPopular && (
        <div className={styles.popular}>
          <p>Most Popular</p>
        </div>
      )}
      <Box
        mb={2}
        backgroundColor={index % 2 === 0 ? 'primary.main' : 'lightBlue.main'}
        py={0.5}
        width="100%"
      />
      <Box minHeight="165px" sx={{ fontFamily: 'SF Pro Display' }}>
        <Typography color={'black.light'} fontSize={'23px'} fontWeight={'600'}>
          {packageItem.packageName}
        </Typography>
        <Typography color={'#6F6F6F'} fontSize={'16px'} fontWeight={'400'}>
          {packageItem.packageDescription}
        </Typography>
        <Typography py={1} fontSize={'33px'} fontWeight={600} color={'primary'}>
          £{packageItem.price}
        </Typography>
        <CustomButton
          variant={isSelected ? 'outlined' : 'contained'}
          classes={styles.btn}
          sx={{
            color: isSelected ? 'primary.main' : 'textWhite.main',
            fontWeight: '600',
            boxShadow: 'none',
            fontSize: '19px',
            fontFamily: 'SF Pro Display',
          }}
          onClick={() => handleButtonClick(index)}
          disabled={isPackageDisabled(id)}
        >
          {isSelected ? 'Selected' : 'Select'}
        </CustomButton>
      </Box>
      <Box
        border="1px solid"
        borderColor="borderLight.light"
        mt={2}
        width={'100%'}
      />
      <Bullet
        text={`${packageItem?.minutes} minutes`}
        sx={{ fontSize: '16px', color: '#4B3E3D' }}
      />
      {packageItem.features.map((feature, featureIndex) => (
        <Bullet
          key={featureIndex}
          text={feature}
          sx={{ fontSize: '16px', color: '#4B3E3D' }}
        />
      ))}
    </Box>
  );
}
