import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Alert } from '@mui/material';
import { Radio } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

import OrderCard from '../Order/OrderCard';
import StripeForm from './StripeForm';

import './Payment.scss';
import Layout from '../../layouts/Layout/Layout';

const paymentMethodsData = [
  {
    logo: '/credit-card.svg',
    key: 'visa',
    text: 'Credit or Debit card',
    styles: {
      marginTop: '5px',
    },
  },
  {
    logo: '/ApplePay.svg',
    key: 'applePay',
    styles: {
      marginTop: '5px',
    },
  },
  {
    logo: '/Gpay.svg',
    key: 'gpay',
    styles: {
      marginTop: '8px',
    },
  },
];

const Payment = () => {
  loadStripe.setLoadParameters({ advancedFraudSignals: false });
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const navigate = useNavigate();

  const [isPaymentFailed, setPaymentFailed] = useState(false);

  const items = useSelector((state) => state.cart).items;
  const totalMinutes = items?.reduce((acc, curr) => acc + curr?.minutes, 0);
  const isBirthdayPackSelected = items?.find(
    (item) => item?.packageName === 'Birthday Party Pack'
  );

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('visa');

  useEffect(() => {
    if (items.length <= 0) {
      navigate('/user/dashboard/packages');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {isPaymentFailed && (
        <Box mt="20px" maxWidth="90%" mx="auto">
          <Alert
            variant="filled"
            severity="error"
            onClose={() => setPaymentFailed(false)}
          >
            Payment Failed
          </Alert>
        </Box>
      )}
      <Box
        className="main_box"
        display="flex"
        justifyContent="space-around"
        gap="20px"
      >
        <Box className="left_box">
          <Box
            display="flex"
            flexDirection="column"
            sx={{ paddingTop: '20px' }}
          >
            <Box display="flex" flexDirection="column" gap="10px">
              <Typography color="black.light" fontWeight="600">
                Choose Payment Method
              </Typography>
              {paymentMethodsData.map(({ key, logo, text, styles }) => (
                <Box
                  key={key}
                  width="100%"
                  height="48px"
                  border={`${
                    selectedPaymentMethod === key ? '2px solid ' : '2px solid '
                  }`}
                  borderColor={`${
                    selectedPaymentMethod === key
                      ? 'primary.main'
                      : 'borderLight.main'
                  }`}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  borderRadius="8px"
                  color="primary"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedPaymentMethod(key)}
                >
                  <Radio
                    type="radio"
                    color="primary"
                    name="payment"
                    value={key}
                    checked={selectedPaymentMethod === key}
                  />
                  <Box display="flex" alignItems="center">
                    <Box sx={{ ...styles }}>
                      <img src={logo} height="20px" alt="icon" />
                    </Box>
                    {text && (
                      <Typography
                        fontWeight={500}
                        color="#000"
                        fontSize="16px"
                        ml="10px"
                      >
                        {text}
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            <Elements stripe={stripePromise}>
              <StripeForm setPaymentFailed={setPaymentFailed} />
            </Elements>
          </Box>
        </Box>
        <Box sx={{ paddingTop: '20px' }} className="right_box">
          <Box minWidth="90%" marginX="auto">
            <Typography color="black.light" fontWeight="600" marginBottom="9px">
              Package
            </Typography>
            <Box
              justifyContent="space-between"
              paddingX="15px"
              width="100%"
              height="48px"
              border="2px solid"
              borderColor="borderLight.main"
              display="flex"
              flexDirection="row"
              alignItems="center"
              borderRadius="8px"
              color="primary"
              sx={{ backgroundColor: 'textWhite.main' }}
            >
              <Typography
                color="black.light"
                fontWeight="600"
                fontSize="14px"
                borderLeft="4px solid"
                borderColor="primary.main"
                paddingX="6px"
              >
                {items?.map((item) => item?.packageName).join(', ')}
              </Typography>
              {!isBirthdayPackSelected && (
                <Typography
                  color="black.light"
                  fontWeight="600"
                  fontSize="14px"
                >
                  {totalMinutes} Minutes
                </Typography>
              )}
            </Box>
            <Typography
              className="underline_text"
              fontSize="14px"
              onClick={() => navigate('/user/dashboard/packages')}
            >
              change package
            </Typography>
          </Box>
          <div className="order_info">
            <OrderCard paymentMethod />
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default Payment;
