import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { CloseOutlined } from '@material-ui/icons';
import TransactionCard from './TransactionCard';
import { useGetTransactionListQuery } from '../../../services/apis/SettingService';
import { FormatDate } from '../../../common/utils/DateUtils';
import { useGetAccountDetails } from '../../../common/hooks/useGetAccountDetails';

const TranscatioListModal = ({ open, onClose }) => {
  const { account } = useGetAccountDetails();
  const { data: transactionList, error } = useGetTransactionListQuery(
    account?.accountId,
    {
      skip: !Boolean(account?.accountId),
    }
  );
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const [sortedTransactionList, setSortedTransactionList] = useState(null);
  useEffect(() => {
    if (transactionList) {
      const sortedTransactions = [...transactionList]?.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp); // Sort descending
      });
      if (showAllTransactions) {
        setSortedTransactionList(sortedTransactions);
      } else {
        const recentTransactions = sortedTransactions.slice(0, 4);
        setSortedTransactionList(recentTransactions);
      }
    }
  }, [transactionList, showAllTransactions]);

  const handleLoadAllTransactions = () => {
    setShowAllTransactions(!showAllTransactions);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
      disableAutoFocus
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: {
            xs: '350px',
            sm: '500px',
            lg: '600px',
            xl: '700px',
          },
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            mb={1}
            alignItems={'center'}
          >
            <Typography fontWeight={'600px'} fontSize={'1.2rem'}>
              Transactions
            </Typography>
            <CloseOutlined
              className="cursor-pointer"
              onClick={() => onClose(0)}
            />
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            // mx={2}
            maxHeight={'380px'}
            overflow={'scroll'}
          >
            <Box mt={1}>
              <TransactionCard
                title={'Package'}
                payment={'Amount'}
                date={'Date'}
              />
            </Box>
            {sortedTransactionList?.map((transaction) => (
              <Box key={transaction.id}>
                <TransactionCard
                  title={transaction.packageName}
                  date={FormatDate(transaction.timestamp, 'DD/MM/YYYY')}
                  payment={'£' + transaction.amount / 100}
                />
              </Box>
            ))}
          </Box>
        </Box>
        {(error || sortedTransactionList?.length === 0) && (
          <Typography textAlign={'center'} mt={2}>
            No Available Transactions.
          </Typography>
        )}
        {transactionList?.length > 4 && (
          <Typography
            color={'lightBlue.main'}
            textAlign={'right'}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            mt={1}
            onClick={handleLoadAllTransactions}
          >
            {showAllTransactions
              ? 'See fewer transactions'
              : 'See older transactions'}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default TranscatioListModal;
