import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useDebounce } from 'use-debounce';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Input from '../../../common/components/Input';
import CountryCodeList from '../../../common/components/CountryCodeList';
import Dropdown from '../../../common/components/Dropdown';
import { findAddress, checkFieldInput } from '../../../utils';
import InputTitle from '../../../common/components/InputTitle';
import { useUpdateProfileMutation } from '../../../services/apis/ProfileService';
import { toast } from 'react-toastify';

const formValues = {
  address: '',
  postCode: '',
  city: '',
  line_1: '',
  line_2: '',
  country: { label: 'United Kingdom', value: '+44', flag: '🇬🇧' },
};

const AddressModal = ({
  setStepNo,
  handleClose,
  refetchChildren,
  childDetail,
}) => {
  const [UpdateProfile] = useUpdateProfileMutation();
  const { address } = childDetail;

  const [addresses, setAddresses] = useState([]);
  const [manualAddress, setManualAddress] = useState(false);

  const validationSchema = Yup.object({
    address: manualAddress ? Yup.string() : Yup.string().required('Required'),
    postCode: !manualAddress
      ? Yup.number()
      : Yup.number()
          .required('Please enter postcode')
          .test(
            'len',
            'Must be 4-6 characters',
            (val) =>
              val && val.toString().length >= 4 && val.toString().length <= 6
          ),

    city: !manualAddress
      ? Yup.string()
      : Yup.string().required('Please add town/city'),
    line_1: !manualAddress
      ? Yup.string()
      : Yup.string()
          .required('Please add line one')
          .trim()
          .matches(
            /^[a-zA-Z0-9\s\-,.#]+$/,
            'Line one can only contain letters, numbers, spaces, hyphens, commas, periods, and pound signs'
          )
          .test(
            'no-html',
            'Line one contains invalid characters',
            (value) => !/<\/?[a-z][\s\S]*>/i.test(value)
          )
          .min(2, 'Line one must be at least 2 characters')
          .max(50, 'Line one must be at most 50 characters'),
    line_2: !manualAddress
      ? Yup.string()
      : Yup.string()

          .trim()
          .matches(
            /^[a-zA-Z0-9\s\-,.#]+$/,
            'Line one can only contain letters, numbers, spaces, hyphens, commas, periods, and pound signs'
          )
          .test(
            'no-html',
            'Line one contains invalid characters',
            (value) => !/<\/?[a-z][\s\S]*>/i.test(value)
          )
          .min(2, 'Line one must be at least 2 characters')
          .max(50, 'Line one must be at most 50 characters'),
    country: !manualAddress ? Yup.object() : Yup.object().required('Required'),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const reqBody = { ...childDetail };
      reqBody.address = values.address;
      if (manualAddress) {
        reqBody.postcode = values.postCode;
        reqBody.address = `${values.line_1}, ${values.country.label}`;
      }
      const response = await UpdateProfile(reqBody);
      if (response.data) {
        refetchChildren();
        toast.success('Address updated successfully!', { autoClose: 1000 });
        handleClose();
      } else if (response.error) {
        toast.error('Failed to update details!');
      }
    },
  });

  const onSelectDropdownOption = (value) => {
    const selectedAddressZipcode = addresses.find(
      (address) => address.label === value
    )?.zipcode;
    setFieldValue('postcode', selectedAddressZipcode);
    setFieldValue('address', value);
  };

  const [debouncedValue] = useDebounce(values.address, 500);

  useEffect(() => {
    if (debouncedValue) {
      findAddress(debouncedValue, setAddresses);
    }
  }, [debouncedValue]);

  return (
    <Box display="flex" flexDirection="column" gap="9px">
      <Typography
        color={'black.light'}
        fontSize="28px"
        fontWeight="700"
        textAlign="center"
      >
        Address Details
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Input
            label="Current"
            disabled={true}
            placeholder=""
            type="text"
            name="address"
            value={address}
            style={{
              backgroundColor: '#ECECEC',
              color: '#4B3E3D',
              fontSize: '16px',
              fontWeight: '400',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTitle label="Enter New" />
          <Dropdown
            name="address"
            clear={manualAddress}
            placeholder="Add address"
            options={addresses}
            error={errors.address}
            touched={touched.address}
            setFieldValue={(value) => onSelectDropdownOption(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontSize="13px"
            mt="8px"
            mb="10px"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'black.main',
              fontWeight: '500',
            }}
            onClick={() => setManualAddress((prevState) => !prevState)}
          >
            Enter Address Manually
          </Typography>
          {manualAddress && (
            <>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="3px"
              >
                <Box width="100%" mt="4px">
                  <CountryCodeList
                    label=""
                    showLabel={true}
                    disable={true}
                    error={errors.country}
                    onSelectChange={(selectedOption) => {
                      setFieldValue('country', {
                        ...selectedOption,
                      });
                    }}
                    countryCode={values.country}
                  />
                </Box>
                <Input
                  type="number"
                  placeholder="Postcode"
                  name="postCode"
                  value={values.postCode}
                  handleChangeEvent={(e) => {
                    const value = e.target.value;
                    if (value.length <= 6) {
                      setFieldValue('postCode', value);
                    }
                  }}
                  handleBlurEvent={handleBlur}
                  error={errors.postCode}
                  touched={touched.postCode}
                />
              </Box>
              <Grid item xs={12}>
                <Input
                  placeholder="Town/city"
                  type="text"
                  name="city"
                  value={values.city}
                  handleChangeEvent={(e) => {
                    checkFieldInput(e, setFieldValue);
                  }}
                  handleBlurEvent={handleBlur}
                  error={errors.city}
                  touched={touched.city}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  placeholder="Address line 1"
                  type="text"
                  name="line_1"
                  value={values.line_1}
                  handleChangeEvent={handleChange}
                  handleBlurEvent={handleBlur}
                  error={errors.line_1}
                  touched={touched.line_1}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  placeholder="Address line 2"
                  type="text"
                  name="line_2"
                  value={values.line_2}
                  handleChangeEvent={handleChange}
                  handleBlurEvent={handleBlur}
                  error={errors.line_2}
                  touched={touched.line_2}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Button
          type="submit"
          disabled={
            (!manualAddress && values.address === '') ||
            (manualAddress &&
              (!values.postCode ||
                !values.city ||
                !values.line_1 ||
                !values.country))
          }
          //   onClick={() => setStepNo("Address")}
          variant="contained"
          sx={{
            backgroundColor: 'primary.main',
            color: 'white',
            py: 1,
            borderRadius: 5,
            width: '100%',
          }}
        >
          Save
        </Button>

        <Box
          color="#4B3E3D"
          pt={2}
          // onClick={handleClose}
          display="flex"
          justifyContent={'center'}
          sx={{ cursor: 'pointer' }}
          onClick={() => setStepNo(1)}
        >
          <Box component="img" mr="10px" src="/back.svg" alt="back"></Box>{' '}
          <span
            style={{ fontSize: '12px', color: '#4B3E3D', fontWeight: '500' }}
          >
            Back
          </span>
        </Box>
      </form>
    </Box>
  );
};

export default AddressModal;
