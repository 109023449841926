import React, { useEffect, useState } from 'react';
import { Radio, Avatar, Grid, Typography, Box } from '@mui/material';
import NoProfile from '../NoServiceComponents/NoProfile';
import { avatar_men } from '../../../shared/images';

const ProfileSelector = ({ profileData, onProfileSelect }) => {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [activeProfiles, setActiveProfiles] = useState(profileData);
  const handleGridClick = (profile) => {
    setSelectedProfile(profile.id.toString());
    onProfileSelect(profile);
  };
  useEffect(() => {
    if (profileData?.length > 0) {
      const activeProfiles = profileData?.filter(
        (profile) => profile && profile.status === 'ACTIVE'
      );

      setActiveProfiles(activeProfiles);
    } else {
      setActiveProfiles([]);
    }
  }, [profileData]);

  return (
    <Box>
      <Typography
        fontSize={'1.2rem !important'}
        my={'16px !important'}
        fontWeight={'700 !important'}
        color={'#4B3E3D !important'}
      >
        Select a Profile
      </Typography>
      <Box>
        {activeProfiles?.length > 0 ? (
          <Grid
            sx={{
              justifyContent: {
                lg: 'end',
                sm: 'center',
              },
            }}
            alignItems={'center'}
          >
            <Box
              width={'100%'}
              // minWidth={"350px"}

              maxHeight={'340px'}
              overflow={'auto'}
            >
              {activeProfiles?.map((profile, index) => (
                <Grid
                  key={profile.id}
                  xs={12}
                  height={'75px'}
                  item
                  display="flex"
                  justifyContent={'space-between'}
                  alignItems="center"
                  border={`1px solid ${profile.profileCardColor}`}
                  borderRadius="8px"
                  backgroundColor={profile.profileCardColor}
                  padding={'10px 16px'}
                  marginBottom={'4px'}
                  onClick={() => handleGridClick(profile)}
                  sx={{
                    cursor: 'pointer',
                    width: {
                      xs: '341px',
                      sm: '310px',
                      lg: '358px',
                      xl: '370px',
                    },
                  }}
                  mb={2}
                  mr={1}
                >
                  <Box
                    display={'flex'}
                    gap={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Avatar
                      alt={profile.firstName}
                      src={profile.picture || avatar_men}
                      sx={{
                        marginRight: '8px',
                        border: '1px solid  #C9C9C9',
                        borderRadius: '50%',
                        height: '55px',
                        width: '55px',
                      }}
                    />
                    <Typography
                      fontSize={'1rem'}
                      color="#FFFFFF"
                      lineHeight={'1.5rem'}
                      variant="h3"
                    >
                      {profile.profileFirstName +
                        ' ' +
                        (profile.profileLastName || '')}
                    </Typography>
                  </Box>
                  <Radio
                    checked={selectedProfile === profile.id.toString()}
                    sx={{
                      color: '#fff',
                      '&.Mui-checked': {
                        color: '#fff',
                      },
                    }}
                    value={profile.id.toString()}
                    name="profile-radio-button"
                  />
                </Grid>
              ))}
            </Box>
          </Grid>
        ) : (
          <NoProfile />
        )}
      </Box>
    </Box>
  );
};

export default ProfileSelector;
