import React, { useState } from 'react';
import SettingCard from './SettingCard';
import { Box } from '@mui/material';
import TranscatioListModal from './Transactions/TranscatioListModal';
import ViewProfile from './ViewProfile';
import { useDispatch } from 'react-redux';
import { setCurrentOtpStep } from '../../store/slices/SettingSlice';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isTransactionModal, setIsTransactionModal] = useState(false);
  const [isProfileModal, setIsProfileModal] = useState(false);

  const handleViewProfileClick = () => {
    setIsProfileModal(true);
    dispatch(setCurrentOtpStep(1));
  };
  const handleSignOutClick = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (e) {
      console.error(e);
    }
  };
  const handleTranscationClick = () => {
    setIsTransactionModal(!isTransactionModal);
  };
  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <TranscatioListModal
        open={isTransactionModal}
        onClose={() => setIsTransactionModal(false)}
      />

      <ViewProfile
        openModal={isProfileModal}
        onClose={() => setIsProfileModal(false)}
      />

      <SettingCard CardTitle={'Marketing Preferences'} isMarketing={true} />
      <SettingCard
        CardTitle={'Transactions'}
        onCardClick={handleTranscationClick}
      />
      <SettingCard
        CardTitle={'View Profile'}
        onCardClick={handleViewProfileClick}
      />
      <SettingCard CardTitle={'Sign Out'} onCardClick={handleSignOutClick} />
      <SettingCard CardTitle={'Delete Profile'} isDisabled />
    </Box>
  );
};

export default Settings;
