import {
  Avatar,
  Box,
  Radio,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import './ChildCard.scss';
import { useEffect, useState } from 'react';
import { getProfileImage } from '../utils/getProfileImage';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import { uploadProfileImage } from '../utils/uploadProfileImage';

const ChildCard = ({
  profileId,
  male,
  fname,
  lname,
  age,
  profileCardColor,
  mins,
  modal = false,
  onClick,
  checked,
  style,
  sx,
  isEditModal = false,
}) => {
  const [image, setImage] = useState();
  const [uploadingImage, setUploadingImage] = useState(false);

  const handleImageChange = async (event) => {
    const newImage = event.target?.files?.[0];
    if (newImage) {
      if (image) {
        URL.revokeObjectURL(image);
      }
      setUploadingImage(true);

      await uploadProfileImage(newImage, profileId)
        .then(() => {
          setImage(URL.createObjectURL(newImage));
        })
        .catch(() => {
          toast.error('Failed to upload image!');
        })
        .finally(() => {
          setUploadingImage(false);
        });
    }
  };
  useEffect(() => {
    getProfileImage(profileId)
      .then(setImage)
      .catch(() => {});
  }, [profileId]);

  // useEffect(() => {
  //   return () => {
  //     console.log('unmounting detected');
  //     getProfileImage(profileId)
  //       .then(setImage)
  //       .catch(() => {});
  //   };
  // }, []);

  return (
    <Box
      className="parent_Box"
      sx={{
        backgroundColor:
          profileCardColor || `${male ? 'lightBlue.main' : 'pink.main'}`,
      }}
      width="100%"
      onClick={onClick}
      minHeight={modal ? '' : '142px'}
    >
      <Box
        className="avatar_container"
        justifyContent={modal ? 'space-between' : ''}
      >
        <Box display="flex" alignItems="center">
          {isEditModal ? (
            <Box style={{ borderRadius: 999, position: 'relative' }}>
              <Avatar
                alt="Profile Picture"
                src={image}
                sx={{
                  height: '94px',
                  width: '94px',
                  border: 'solid 2px #F39200',
                }}
              />
              <IconButton
                style={{
                  position: 'absolute',
                  borderRadius: 999,
                  bottom: '4px',
                  right: '0px',
                  height: '32px',
                  width: '32px',
                  background: 'white',
                  border: 'solid 2px #F39200',
                }}
              >
                <label
                  htmlFor={'profile-pic-selector-button-edit-profile'}
                  className="cursor-pointer"
                >
                  {uploadingImage ? (
                    <CircularProgress size={'16px'} sx={{ display: 'flex' }} />
                  ) : (
                    <EditIcon
                      sx={{
                        height: '22px',
                        width: '22px',
                        display: 'flex',
                      }}
                    />
                  )}
                  <input
                    id="profile-pic-selector-button-edit-profile"
                    accept="image/jpeg,image/jpg"
                    type="file"
                    name={'profileImage'}
                    hidden
                    disabled={uploadingImage}
                    onChange={handleImageChange}
                  />
                </label>
              </IconButton>
            </Box>
          ) : (
            <Avatar
              src={image}
              sx={{
                width: '71px',
                height: '71px',
              }}
            />
          )}
          <Typography
            variant={modal ? 'h5' : 'h4'}
            color="white"
            fontSize={style ? '23px' : '28px'}
            fontWeight={'700'}
            ml="1.5rem"
            sx={sx}
          >
            {fname} {lname}
          </Typography>
        </Box>
        {modal && (
          <Radio
            checked={checked}
            sx={{
              color: 'textWhite.main',
              '&.Mui-checked': { color: 'textWhite.main' },
            }}
          />
        )}
      </Box>
      {!modal && (
        <Box className="second_container">
          <Typography fontSize={style ? '16px' : '19px'}>Age: {age}</Typography>
          <Typography fontSize={style ? '16px' : '19px'}>
            {mins === null || 0 ? '0' : mins} Minutes
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ChildCard;
