import { Grid, Divider, Typography, Box } from '@mui/material';
import { BookingsCard } from './BookingsCard';
import { useGetBookingListMutation } from '../../../services/apis/BookingService';
import Shimmer from '../../../common/components/Skeletons/Shimmer';
import NoBookings from '../NoServiceComponents/NoBookings';
import { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { useGetAccountDetails } from '../../../common/hooks/useGetAccountDetails';

const BookingList = () => {
  const { account } = useGetAccountDetails();
  const [getBookingList, { data: bookingList, error, isLoading }] =
    useGetBookingListMutation();

  const [sortedBookingList, setSortedBookingList] = useState(null);
  const fetchBookings = async () => {
    try {
      await getBookingList(account?.accountId).unwrap();
    } catch (err) {
      console.error('Failed to fetch bookings:', err);
    }
  };
  useEffect(() => {
    if (bookingList?.length > 0) {
      const pendingBookings = [...bookingList].filter(
        (booking) => booking.booking.status === 'Confirmed'
      );

      const sorted = [...pendingBookings].sort((a, b) => {
        const dateA = moment(
          `${a.booking.date} ${a.booking.sessionTime}`,
          'YYYY-MM-DD HH:mm:ss'
        );
        const dateB = moment(
          `${b.booking.date} ${b.booking.sessionTime}`,
          'YYYY-MM-DD HH:mm:ss'
        );
        return dateA - dateB;
      });
      setSortedBookingList(sorted);
    }
  }, [bookingList]);

  useEffect(() => {
    if (account) {
      fetchBookings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  if (isLoading)
    return (
      <div>
        <Shimmer />
      </div>
    );

  if (error)
    return (
      <div>
        <NoBookings />
      </div>
    );

  return (
    <>
      {sortedBookingList && (
        <Box>
          <Typography my={2} color={'#4B3E3D'} fontWeight={'700'}>
            Reservations({sortedBookingList?.length})
          </Typography>
          {bookingList?.length ? (
            <Grid container gap={3}>
              {sortedBookingList?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={5}
                  key={item?.booking?.bookingId}
                  display="flex"
                  justifyContent={'space-between'}
                  border={'1px solid #D6D6D6'}
                  borderRadius={'8px'}
                  sx={{ boxShadow: '0px 1px 4px 0px #00000029' }}
                >
                  <Divider
                    orientation="vertical"
                    variant={'middle'}
                    margin={'5px'}
                    flexItem
                    sx={{
                      border: `2px solid ${
                        index % 2 !== 0 ? '#50A7DA' : '#F39200'
                      }`,
                    }}
                  />
                  <BookingsCard
                    date={item.booking.date}
                    time={item.booking.sessionTime}
                    status={item.booking.status}
                    duration={item.booking.slotsBooked}
                    barcode={item.barcode}
                    name={
                      item.profileFirstName + ' ' + (item.profileLastName || '')
                    }
                    bookingId={item.booking.bookingId}
                    refetchBookingList={fetchBookings}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <NoBookings />
          )}
        </Box>
      )}
    </>
  );
};

export default BookingList;
