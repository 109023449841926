import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { COUNTRY_CODES } from '../utils/CountryCodeList';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
const CountryCodeList = ({
  error = false,
  onSelectChange,
  countryCode,
  showLabel,
  disable = false,
  label = 'Country Code',
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Autocomplete
      disableClearable
      id="country-code"
      options={COUNTRY_CODES}
      getOptionLabel={(option) =>
        `${option.flag} ${showLabel ? option.label : option.value}`
      }
      value={countryCode}
      onChange={(event, newValue) => onSelectChange(newValue)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      clearOnEscape={false}
      clearIcon={false}
      renderOption={(props, option) => (
        <li {...props}>
          {option.flag} {showLabel ? option.label : option.value}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          paddingTop="0px"
          {...params}
          label={label}
          variant="outlined"
          error={error}
        />
      )}
      sx={{
        width: '100%',
        '& .MuiAutocomplete-input': {
          fontSize: matches ? '0.8rem' : '14px',
          fontWeight: 400,
        },
        '& .MuiAutocomplete-inputRoot': {
          height: '49px',
        },
      }}
    />
  );
};

export default CountryCodeList;
