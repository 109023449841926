import { Box, CircularProgress, ThemeProvider } from '@mui/material';
import { useRoutes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import KidzCubicleTheme from './theme/KidzCubicleTheme';
import Routes from './routes';
import { Amplify } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

Amplify.configure({
  Auth: {
    Cognito: {
      // identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      userPoolClientId: process.env.REACT_APP_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_DOMAIN,
          scopes: [
            'openid',
            'profile',
            'email',
            'phone',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'token',
          providers: ['Google', 'Facebook'],
        },
      },
    },
  },
  Storage: {
    S3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_REGION,
    },
  },
});

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const routing = useRoutes(Routes(isAuthenticated));
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchAuthSession();
        if (!res.userSub) throw new Error('Unauthenticated');
        setIsAuthenticated(true);
      } catch (e) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [location.pathname]);

  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ToastContainer
        className="toast"
        limit={10000}
        progressStyle={{
          backgroundColor: '#F39200',
        }}
      />
      <ThemeProvider theme={KidzCubicleTheme}>{routing}</ThemeProvider>
    </>
  );
};

export default App;
