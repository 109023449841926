import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';
import { useGetAllocationStatusQuery } from '../../services/apis/PaymentService';
import AssignPercentageToChildModal from '../../features/Payment/AssignPercentageToChildModal';
import RegisterPhoneDialog from '../../features/RegisterPhoneDialog';

const UserLayout = () => {
  const [intent, setIntent] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openPhoneDialog, setOpenPhoneDialog] = useState(false);
  const { account } = useGetAccountDetails();
  const { data } = useGetAllocationStatusQuery(account?.accountId, {
    skip: !Boolean(account?.accountId) && account?.accountStatus !== 'INACTIVE',
  });

  useEffect(() => {
    if (account && account.accountStatus === 'INACTIVE') {
      setOpenPhoneDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (data && data.length > 0) {
      const transaction = data[data.length - 1];
      if (transaction.allocationStatus === 'False') {
        // setOpenDialog(true);
        setIntent(transaction);
      }
    }
  }, [data]);

  return (
    <Box className="UserLayout">
      <Box component="main" className="UserLayout--outlet">
        <Outlet />
      </Box>
      {openDialog && (
        <AssignPercentageToChildModal
          open={openDialog}
          setOpen={setOpenDialog}
          intentData={{
            amount: intent.amount,
            metadata: { packageId: intent.packageId },
            description: intent.minutes,
          }}
          minutes={intent.minutes}
        />
      )}
      <RegisterPhoneDialog
        open={openPhoneDialog}
        onClose={() => setOpenPhoneDialog(false)}
      />
    </Box>
  );
};

export default UserLayout;
