import api from '../api';

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddProfile: build.mutation({
      query: (profileData) => ({
        url: 'profile',
        method: 'POST',
        body: profileData,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        // },
      }),
    }),
    GetAllProfiles: build.query({
      query: (id) => ({
        url: `profile/by-account/${id}`,
        method: 'GET',
      }),
    }),
    UpdateProfile: build.mutation({
      query: (payload) => ({
        url: `profile/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    UpdateProfileMinutes: build.mutation({
      query: (childMinutes) => ({
        url: 'profile/batch-update',
        method: 'PUT',
        body: {
          profiles: childMinutes,
        },
      }),
    }),
    GetProfileById: build.query({
      query: (id) => ({
        url: `profile/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useAddProfileMutation,
  useGetAllProfilesQuery,
  useUpdateProfileMutation,
  useUpdateProfileMinutesMutation,
  useGetProfileByIdQuery,
} = profileApi;

export const { endpoints } = profileApi;
