import { Box, Typography, Button } from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useMakePaymentMutation } from '../../services/apis/PaymentService';
import { useState } from 'react';
import AssignPercentageToChildModal from './AssignPercentageToChildModal';

const StripeForm = ({ setPaymentFailed }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [open, setOpen] = useState(false);
  const [intentData, setIntentData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { paymentIntentId } = useSelector((state) => state.cart);

  const [makePayment] = useMakePaymentMutation();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      setPaymentFailed(true);
      return;
    }

    if (!error && token) {
      const response = await makePayment({
        paymentIntentId,
        cardToken: token.id,
      });
      if (response.data) {
        setIntentData(response.data);
        setOpen(true);
        setIsLoading(false);
      } else {
        setPaymentFailed(true);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <AssignPercentageToChildModal
        open={open}
        setOpen={setOpen}
        intentData={intentData}
        minutes={intentData?.description}
      />
      <form className="stripe-form" onSubmit={handleSubmit}>
        <Box paddingY="10px">
          <Typography
            color="#4B3E3D"
            fontWeight="600"
            marginBottom="10px"
            fontSize="18px"
          >
            Card Information
          </Typography>
          <Box width="100%">
            <Box>
              <CardElement
                className="card-element"
                options={{ disableLink: true }}
              />
            </Box>
            {/* <Box marginLeft="-10px">
              <Checkbox
                color="primary"
                inputProps={{
                  "aria-label": "save my details for future purchases",
                }}
              />
              <Typography variant="caption" component="span" fontSize="12px">
                Save My Details For Future Purchases
              </Typography>
            </Box> */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fontWeight="600"
              sx={{
                borderRadius: '100px',
                color: '#ffffff',
                textTransform: 'capitalize',
                width: '174px',
                height: '48px',
                fontSize: '18px',
                margin: '12px 0px !important',
              }}
            >
              {!isLoading ? (
                'Make Payment'
              ) : (
                <CircularProgress size={22} color="textWhite" />
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default StripeForm;
